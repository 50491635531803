import React from "react"
import styled from "styled-components"

import { Heading } from "../components/styles"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Service from "../components/service"
import Container from "../components/container"
import Team from "../components/Team"

const FlexList = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flexStart;
`

const ContentArea = styled.div`
  background: ${props => (props.alternate ? "#f2f2f2" : "#ffffff")};
  text-align: ${props => (props.center ? "center" : "left")};
  min-height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const IndexPage = ({ data }) => (
  <Layout logo="/hansodevelopment-logo-horizontal-light.svg">
    <Seo title="Hanso Development" />
    <Hero title="Creativity takes courage." image="creativity" />

    <ContentArea center>
      <Heading center>Our Company</Heading>
      <p>
        The Hanso Group is an IT Consulting and DevOps company. With us, your
        journey to the cloud is in safe hands.
      </p>
    </ContentArea>

    <ContentArea alternate>
      <FlexList>
        <Container
          name="What We Do"
          description="We consult and support you on your way to the cloud in a sustainable and resilient way."
        />
        <Container image="Streets of Singapore" />
        <Container image="Singapore Cloud Forest" />
        <Container
          name="Our Values"
          description="We value the big picture. Your processes and services are interconnected – as is our way of handling them."
        />
      </FlexList>
    </ContentArea>

    <ContentArea>
      <Heading center style={{ padding: `5rem` }}>
        Core Services
      </Heading>
      <FlexList>
        <Service
          name="Collaboration"
          description="E-Mail, SharePoint Online, Teams – we will help you cut your way through the collaboration jungle, pick the right tools and define a proper strategy."
        />
        <Service
          name="Cloud Migration"
          description="Reducing your local server footprint is almost always a reasonable thing to do. We will help you on your way to a cloud based data center."
        />
        <Service
          name="DevOps"
          description="We don't only help defining your collaboration and communication strategy but also implement, run and support it."
        />
      </FlexList>
    </ContentArea>

    <Team />
  </Layout>
)

export default IndexPage
